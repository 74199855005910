


































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {
    apiDeliverymanDetail,
    apiGetCommunity,
    addCommunityDeliveryman,
    delCommunityDeliveryman
} from '@/api/delivery'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddCategory extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    hasPid = 0
    data: any = []

    community_ids: any = []
    communityList: any = []

    // 添加小区
    handleAdd(community_ids:any) {
        this.loading = true
        let params: any = {
            deliveryman_id: this.id,
            community_ids: community_ids.toString()
        }
        addCommunityDeliveryman(params).then((res: any) => {
            this.loading = false
            this.getcommunityList()
        })
    }
    // 删除小区
    handleDel(community_ids:any) {
        this.loading = true
        let params: any = {
            deliveryman_id: this.id,
            community_ids: community_ids.toString()
        }
        delCommunityDeliveryman(params).then((res: any) => {
            this.loading = false
            this.getcommunityList()
        })
    }
    // 小区详情
    getCategoryDetail() {
        this.loading = true
        apiDeliverymanDetail(this.id).then((res: any) => {
            if (res.pid) {
                this.hasPid = 1
            }

            this.loading = false
        })
    }
    // 获取小区列表
    getcommunityList() {
        apiGetCommunity({ deliveryman_id: this.id }).then((res: any) => {
            let rightArr: any = []
            res.right.forEach((item: any) => {
                this.community_ids.push(item.community_id)
                rightArr.push({
                    id: item.community_id,
                    name: item.community.name,
                    disabled: true
                })
            })
            this.data = [...res.left, ...rightArr]
        })
    }
    
    filterMethod(query: any, item: any) {
        return item.pinyin.indexOf(query) > -1
    }
    // 左右切换小区
    transforChange(val:any, direction:any, arr:any ) {
        console.log(val, direction, arr)
        if(direction == 'left') {
            return;
            this.handleDel(arr)
        }else {
            this.handleAdd(arr)
        }
    }

    created() {
        this.id = this.$route.query.id

        this.getcommunityList()
        // const data = [];
        // for (let i = 1; i <= 15; i++) {
        //     data.push({
        //     key: i,
        //     label: `备选项 ${ i }`,
        //     disabled: i % 4 === 0
        // });}
        // this.data = data
    }
}
